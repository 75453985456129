import React from "react";
import { navigate } from "gatsby"

import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

import './langswitcher.scss'

export default () => {
    const [showLanguages, setShowLanguages] = React.useState(false)

    const changeLang = (language) => {
        
        window.location.pathname.indexOf("blog") > -1 ?
        showLanguages && navigate(`/${language}/blog`)
        :
        showLanguages && changeLocale(language)
    }
    return (
        <IntlContextConsumer>
            {({ languages, language: currentLocale }) => (

                <div onClick={() => setShowLanguages(!showLanguages)} className={`languageswitcher ${showLanguages ? "languageswitcher--show" : ""}`}>

                    {languages.map(language => (
                        <button
                            key={language}
                            onClick={() => changeLang( language)}
                            className={currentLocale === language ? `active` : ``}
                        >
                            {language === "en" && "english"}
                            {language === "cs" && "czech"}
                        </button>

                    ))}
                </div>
            )
            }
        </IntlContextConsumer>
    )
}
