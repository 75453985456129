import { Link } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from 'gatsby'
import { FormattedMessage } from "gatsby-plugin-intl"
import Img from 'gatsby-image'
import React from "react"
import { Logo, MenuIcon, Close } from '../Icons'
import Langswitcher from '../Langswitcher'
import scrollTo from 'gatsby-plugin-smoothscroll'

import './header.scss'

export default ({ featuredImage }) => {
    const data = useStaticQuery(graphql`
        query {
            file(name: {eq: "hero"}) {
                childImageSharp {
                    fluid (maxWidth: 3000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    const [nav, setNav] = React.useState(false)

    const draw = (svgPath) => {
        if(svgPath) {
            var path = document.querySelector(svgPath);
            var length = path.getTotalLength();
            // Clear any previous transition
            path.style.transition = path.style.WebkitTransition =
                'none';
            
            // Set up the starting positions
            path.style.strokeDasharray = length + ' ' + length;
            path.style.strokeDashoffset = length;

            // Trigger a layout so styles are calculated & the browser 
            // picks up the starting position before animating
            path.getBoundingClientRect();

            // Define our transition
            path.style.transition = path.style.WebkitTransition =
                'stroke-dashoffset 2s ease-in-out';
            path.style.stroke =
                'var(--accentMain)';
            // Go!
            path.style.strokeDashoffset = '0';
        }
    }

    
    React.useEffect(
        () => {
            if (nav === true) {
                document.body.classList.add('hideOverflow')
            } else {
                document.body.classList.remove('hideOverflow')
            }
            
            if(document.querySelector('.index .logoPath1') && document.querySelector('.index .logoPath2')) {
                draw('.index .logoPath1')
                draw('.index .logoPath2')
            }
           
        }
    )
    return (
        <header className="header" >


            <div className="header__shape">
                <Img fluid={featuredImage ? featuredImage : data.file.childImageSharp.fluid} />
            </div>
            <div className="header__top">
                <div className="container">
                    <Langswitcher />
                </div>
            </div>
            <div className="container">
                
                <Link
                    to="/"
                    className="header__logo">
                    <Logo />
                </Link>
                <button 
                    className="header__menu"
                    onClick={() => setNav(!nav)}>
                    <MenuIcon />
                </button>
                <nav className={`nav ${nav ? 'nav--open' : ''}`}>
                    <button 
                        className="nav__close"
                        onClick={() => setNav(!nav)}>
                        <Close />
                    </button>
                    <button onClick={() => {scrollTo('#portfolio'); setNav(!nav)}}>Portfolio</button>
                    <button onClick={() => {scrollTo('#about'); setNav(!nav)}}><FormattedMessage defaultMessage="About" id="menu.about" /></button>
                    <button onClick={() => {scrollTo('#contact'); setNav(!nav)}}><FormattedMessage defaultMessage="Contact" id="contact.headline" /></button>
                </nav>
            </div>

        </header >
    )
}