import React from 'react'
import { Phone, Email, Github, Linkedin, Twitter, Facebook, TechLogo } from '../../components/Icons'
import { FormattedMessage } from "gatsby-plugin-intl"

import './footer.scss'

export default () =>
    <footer className="footer" id="contact">
        <div className="footer__shape">
        </div>
        <div className="container">
            <div className="footer__headline">
            <FormattedMessage defaultMessage="Contact" id="contact.headline" tagName="h2"/>
            </div>
            <div className="footer__contact">
                <a href="mailto:matej@petriczech.com"><Email /> <span>matej@petriczech.com</span></a>
                <a href="tel:00420 608 23 28 29"><Phone /> <span>+420 608 23 28 29</span></a>
            </div>
            <div className="footer__social">
                <a href="https://github.com/mpetricek" rel="noopener noreferrer"><Github /></a>
                <a href="https://www.linkedin.com/in/petriczech/" rel="noopener noreferrer"><Linkedin /></a>
                <a href="https://twitter.com/petriczechcom" rel="noopener noreferrer"><Twitter /></a>
                <a href="https://www.facebook.com/petriczechcom/" rel="noopener noreferrer"><Facebook /></a>
            </div>
            <div className="footer__built">
            <FormattedMessage defaultMessage="Built with these beatiful tools" id="contact.disclaimer" tagName="span"/>
                <a rel="noopener noreferrer" href="https://reactjs.org/" target="_blank"><TechLogo iconType="react" /></a>
                <a rel="noopener noreferrer" href="https://www.gatsbyjs.org/" target="_blank"><TechLogo iconType="gatsby" /></a>
                <a rel="noopener noreferrer" href="https://www.netlifycms.org/" target="_blank"><TechLogo iconType="netlifycms" /></a>
                <a rel="noopener noreferrer" href="https://affinity.serif.com/en-gb/" target="_blank"><TechLogo iconType="affinity" /></a>
            </div>
            
        </div>
        <div className="footer__legal">
                <p>Matěj Petříček | Kulturní 1768, Rožnov p. R. | IČO: 76335119</p>
        </div>
    </footer>